(($) => {
  let elements = Array.from(document.querySelectorAll('blockquote'));
  elements = elements.concat(Array.from(document.querySelectorAll('.component-splash .component__description')));
  const offset = window.innerHeight * .666;
  window.addEventListener('scroll',() => {
    elements.forEach(el => {
      if (!el.active) {
        const box = el.getBoundingClientRect();
        if (box.y > 0 && box.y <= offset) {
          el.active = true;
          el.classList.add("active");
        }
      }
    })
  })
})(jQuery);